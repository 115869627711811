import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { TokenStorageService } from '@auth/services/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  public constructor(private router: NavController, private tokenStorage: TokenStorageService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve) => {
      this.tokenStorage
        .loadToken()
        .then(() => {
          if (this.tokenStorage.getRoles().find((r) => r === 'ROLE_ADMIN') === undefined) {
            if (this.tokenStorage.getRoles().find((r) => r === 'ROLE_OWNER') === undefined) {
              this.router.navigateRoot('/owner/dashboard');
              resolve(false);
            } else {
              this.router.navigateRoot('/user/dashboard');
              resolve(false);
            }
          }

          resolve(true);
        })
        .catch(() => {
          this.router.navigateRoot('/user/dashboard');
          resolve(false);
        });
    });
  }
}
