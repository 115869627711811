<div class="file__upload" [ngClass]="{ light: light, error: false === success }">
  <p>
    <span class="icon" [ngSwitch]="upload.file.type">
      <i *ngSwitchCase="'application/pdf'" class="fal fa-file-pdf"></i>
      <i *ngSwitchCase="'image/png'" class="fal fa-image"></i>
      <i *ngSwitchCase="'image/jpg'" class="fal fa-image"></i>
      <i *ngSwitchDefault class="fal fa-file"></i>
    </span>
    <span class="d-flex d-md-none">{{(upload.file.name)? (upload.file.name | slice:0:2)+'...':(upload.file.name)}}</span>
    <span class="d-none d-md-flex">{{(upload.file.name)? (upload.file.name | slice:0:35)+'...':(upload.file.name)}}</span>
    <span class="size">{{ formatBytes(upload.file.size) }}</span>
  </p>
  <div>
    <ion-button color="danger" class="delete" (click)="onDelete()" *ngIf="delete">
      <i class="far fa-trash"></i>
    </ion-button>
  </div>
  <div class="pourcent" [style.width]="progress + '%'"></div>
</div>
