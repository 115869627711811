import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  constructor(private storage: Storage, private translateService: TranslateService) {}

  /**
   * Change language.
   * @param lang language
   */
  public changeLang(lang: string): void {
    this.createStorage();
    this.storage.set('lang', lang);
    this.translateService.use(lang);
  }

  /**
   * Get current language.
   */
  public getCurrentLang(): Promise<string> {
    this.createStorage();
    return this.storage.get('lang');
  }

  public async createStorage() {
    this.storage = new Storage();
    await this.storage.create();
  }
}
