import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentRequest extends AbstractRequest {
  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/documents/${id}`);
  }
}
