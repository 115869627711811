import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(public translate: TranslateService) { }

  public trad(key: string): string {
    switch (key) {
      case 'PROOF_OF_ID':
        return this.translate.instant('document.status.identity_card');
      case 'PROOF_OF_IBAN':
        return this.translate.instant('document.status.IBAN');
      case 'PROOF_OF_REGISTRATION':
        return this.translate.instant('document.status.PROOF_OF_REGISTRATION');
      case 'PROOF_OF_ADDRESS':
        return this.translate.instant('document.status.PROOF_OF_ADDRESS');
      case 'BUSINESS_STATUS':
        return this.translate.instant('document.status.BUSINESS_STATUS');
      case 'ACCOUNTING_REPORT':
        return this.translate.instant('document.status.ACCOUNTING_REPORT');
      case 'passport':
        return this.translate.instant('document.status.passport');
      case 'proof_of_address':
        return this.translate.instant('document.status.proof_of_address');
      case 'driving_license':
        return this.translate.instant('document.status.driving_license');
      case 'identity_card':
        return this.translate.instant('document.status.identity_card');
      case 'student_card':
        return this.translate.instant('document.status.student_card');
      case 'legal_insurance':
        return this.translate.instant('document.status.legal_insurance');
      case 'resident_permit':
        return this.translate.instant('document.status.resident_permit');
      case 'rc_resort':
        return this.translate.instant('document.status.rc_resort');
      case 'PROOF_OF_REFUSE_CASHOUT':
        return this.translate.instant('document.status.PROOF_OF_REFUSE_CASHOUT');
      case 'PROOF_OF_NEGOCIATING_CASHOUT':
        return this.translate.instant('document.status.PROOF_OF_NEGOCIATING_CASHOUT');
    }

    return key;
  }

  public uniformType(type: string): string {
    switch (type) {
      case 'PROOF_OF_ID':
        return 'identity_card';
      case 'PROOF_OF_ADDRESS':
        return 'proof_of_address';
    }

    return type;
  }
}
