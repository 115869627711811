import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-informations-component',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
})
export class InformationsComponent {
  @Input() public text = '';

  @Input() public otherClass = '';
}
