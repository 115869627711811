import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'customDateTimeToFormat'
})
export class CustomDateTimeToFormatPipe implements PipeTransform {
  transform(value: string | Date | undefined | null, format: string): string {
    let dateTime: DateTime;

    if (typeof value === 'string') {
      // Vérifier si la valeur correspond au format "AAAA-MM-JJ"
      const dateParts = value.split('-');
      if (dateParts.length === 3) {
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]);
        const day = parseInt(dateParts[2]);
        // Vérifier si les parties de la date sont des nombres valides
        if (!Number.isNaN(year) && !Number.isNaN(month) && !Number.isNaN(day)) {
          dateTime = DateTime.fromObject({ year, month, day });
        } else {
          // La valeur n'est pas au format attendu
          return '';
        }
      } else {
        // La valeur n'est pas au format attendu
        return '';
      }
    } else if (value instanceof Date) {
      dateTime = DateTime.fromJSDate(value);
    } else {
      return '';
    }
    dateTime = dateTime.setLocale('fr');
    return dateTime.toFormat(format);
  }
}
