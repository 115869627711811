import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '@shared/services/lang.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TokenStorageService } from '@auth/services/token-storage.service';
import { environment } from '@env/environment';
import { AppUpdateService } from '@shared/services/app-update.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private langService: LangService,
    private tokenStorage: TokenStorageService,
    private appUpdateService: AppUpdateService
  ) {
    this.initializeApp();
    if (environment.production) {
      this.appUpdateService.init();
    }
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();
    });

    this.langService
      .getCurrentLang()
      .then((lang: string) => {
        this.translate.setDefaultLang(lang || 'fr');
      })
      .catch(() => {
        this.translate.setDefaultLang('fr');
      });
  }
}
