import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import Document from '@shared/models/Document';
import Media from '@shared/models/Media';
import { DocumentService } from '@shared/translations/document.service';
import { StatusService } from '@shared/translations/status.service';
import { DocumentsStatusEnum } from '@shared/enums/documents-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent {
  @Output() public fileUploaded = new EventEmitter<Media | boolean>();

  @Input() public document!: Document;

  @Input() public errors: string[] = [];

  @Input() public fileDropedStatus: DocumentsStatusEnum = DocumentsStatusEnum.WAITING_APPROVAL;

  /**
   * If component should accept multiple files.
   */
  @Input() public isMultiple = true;

  public documentStatusEnum = DocumentsStatusEnum;

  public translate!: TranslateService;

  constructor(
    public statusService: StatusService,
    public documentService: DocumentService,
  ) { }
}
