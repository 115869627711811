import {
  Component, forwardRef, Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-boolean-form',
  templateUrl: './boolean-form.component.html',
  styleUrls: ['./boolean-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanFormComponent),
      multi: true,
    },
  ],
})
export class BooleanFormComponent implements ControlValueAccessor {
  private ngModel: any;

  get model(): any {
    return this.ngModel;
  }

  set model(model: any) {
    if (model !== this.ngModel) {
      this.ngModel = model;
      this.onChange(model);
    }
  }

  private holder: string;

  @Input()
  set placeholder(holder: string) {
    this.holder = holder;
  }

  get placeholder(): string {
    return this.holder;
  }

  @Input() label!: string;

  @Input() required!: boolean;

  @Input() lightUI!: boolean;

  @Input() hasBeenSubmitted!: boolean;

  @Input() trueText = this.translate.instant('globals.yes');

  @Input() falseText = this.translate.instant('globals.no');

  @Input() trueValue: any = true;

  @Input() falseValue: any = false;

  public checked!: boolean;

  constructor(private translate: TranslateService) {
    this.holder = '';
  }

  public isFieldInvalid(field: NgModel): boolean | null {
    return (!field.valid && field.touched) || (field.untouched && this.hasBeenSubmitted);
  }

  writeValue(value: any) {
    this.ngModel = value;
    this.onChange(value);
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
