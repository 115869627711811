import { TranslateService } from '@ngx-translate/core';
import { RuleFormValidator } from '@shared/enums/rule-form-validator.enum';
import { DateTime } from 'luxon';
import IFrontError from '../interfaces/IFrontError';

export class FormValidator {
  // eslint-disable-next-line no-empty-function
  constructor(private translate: TranslateService) { }

  private static FORM_KEY = 'errors.';

  public frontError: IFrontError = {
    lastName: '',
    firstName: '',
    birthCity: '',
    city: '',
    country: '',
    postalCode: '',
    street: '',
    phone: '',
    birthAt: '',
    birthCountry: '',
    nationality: '',
    streetCompany: '',
    postalCodeCompany: '',
    cityCompany: '',
    countryCompany: '',
    type: '',
    name: '',
    amount: '',
    reason: '',
    idArea: '',
    startAt: '',
    endAt: '',
    email: '',
  };

  /**
   *
   * @param field who will check by the fieldValidator
   * @param rule is the rule used for value validation
   * @param label is the label which will be validate
   * @param value is the value which will be validate
   *
   * @returns all json of frontErrors
   */
  public fieldValidator(fields: Array<[Array<string>, string, string]>) {
    fields.forEach((field) => {
      const [rules, label, value] = field;

      const response: Array<string> = [];

      if (rules.includes(RuleFormValidator.required)) {
        if (this.requiredValidator(value)) {
          response.push(this.translate.instant(`${FormValidator.FORM_KEY}EMPTY_FIELD`));
          this.frontError[label as keyof IFrontError] = this.initError(response, false);
          return this.frontError;
        }
      }

      if (rules.includes(RuleFormValidator.ageValidator)) {
        if (this.ageValidator(value)) {
          response.push(this.translate.instant(`${FormValidator.FORM_KEY}INVALID_18Y`));
          this.frontError[label as keyof IFrontError] = this.initError(response, false);
          return this.frontError;
        }
      }

      if (rules.includes(RuleFormValidator.dateValidator)) {
        let date;
        if (!DateTime.fromFormat(value, 'dd/MM/yyyy').isValid) {
          date = this.formatBirthAt(value);
        } else {
          date = value;
        }
        if (this.dateValidator(date)) {
          response.push(this.translate.instant(`${FormValidator.FORM_KEY}INVALID_BIRTHAT`));
          this.frontError[label as keyof IFrontError] = this.initError(response, false);

          return this.frontError;
        }
      }

      rules.forEach((rule) => {
        switch (rule) {
          case RuleFormValidator.sizeCharacter:
            if (this.SizeCharacterValidator(value, 200)) {
              response.push(
                this.translate.instant(`${FormValidator.FORM_KEY}MORE_THAN`) +
                200 +
                this.translate.instant(`${FormValidator.FORM_KEY}CARACTERS`)
              );
            }
            break;
          case RuleFormValidator.specialCharacter:
            if (this.isSpecialCharacterValidator(value)) {
              response.push(this.translate.instant(`${FormValidator.FORM_KEY}SPECIAL_CARACTERS`));
            }
            break;
          case RuleFormValidator.isNumber:
            if (this.isNANValidator(value)) {
              response.push(this.translate.instant(`${FormValidator.FORM_KEY}NUMBER`));
            }
            break;
          case RuleFormValidator.isLetter:
            if (this.isLetterValidator(value)) {
              response.push(this.translate.instant(`${FormValidator.FORM_KEY}LETTER`));
            }
            break;
          default:
            break;
        }
      });

      this.frontError[label as keyof IFrontError] = this.initError(response, true);
    });

    return this.frontError;
  }

  /**
   *
   * @param error is all error returned by validator
   * @param dontPut true if you want "don't put" or false if no
   * @returns string of error
   */
  private initError(error: Array<string>, dontPut: boolean): string {
    if (error.length) {
      if (dontPut) {
        return [this.translate.instant(`${FormValidator.FORM_KEY}DONT_PUT`)]
          .concat(error)
          .join(', ')
          .replace(', ', ' ') as string;
      }
      return error.join(', ').replace(', ', ' ') as string;
    }
    return '';
  }

  public ibanValidator(iban: string): boolean {
    const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
    return ibanRegex.test(iban);
  }

  public bicValidator(bic: string): boolean {
    const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
    return bicRegex.test(bic);
  }

  public dateValidator(date: string): boolean {
    if (!date.match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)) {
      return true;
    }
    return false;
  }

  public ageValidator(dateBrut: string): boolean {
    if (this.isAdult(dateBrut) < 18) {
      return true;
    }

    return false;
  }

  private requiredValidator(input: string) {
    if (input === undefined || input === '') {
      return true;
    }

    return false;
  }

  private SizeCharacterValidator(input: string | undefined, size: number) {
    if (input === undefined) {
      return false;
    }
    if (input.length >= size) {
      return true;
    }
    return false;
  }

  private isSpecialCharacterValidator(input: string | undefined) {
    if (input === undefined) {
      return false;
    }
    if (input.match(/(?=.*[#$@!%&*?])/)) {
      return true;
    }
    return false;
  }

  private isNANValidator(input: string |undefined): boolean {
    if (input === undefined) {
      return false;
    }
    if (input.match(/(?=.*[0-9])/)) {
      return true;
    }
    return false;
  }

  private isLetterValidator(input: string | undefined): boolean {
    if (input === undefined) {
      return false;
    }
    if (input.match(/(?=.*[a-zA-Z])/)) {
      return true;
    }
    return false;
  }

  public isAdult(birthAt: string): number {
    if (birthAt !== null && birthAt !== undefined && birthAt !== '') {
      const dateTimeObj = DateTime.fromFormat(birthAt, 'dd/MM/yyyy');
      let date: string;

      if (dateTimeObj.isValid) {
        date = dateTimeObj.toFormat('yyyy-MM-dd');
      } else {
        date = birthAt;
      }

      const birthDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
      const today = DateTime.now();

      const age = today.diff(birthDate, 'years').years;

      return age;
    }
    return -1;
  }

  public resetBirthAt(value: any) {
    const date = new Date(value);

    let m: number | string = date.getMonth() + 1;
    let d: number | string = date.getDate();
    m = m > 9 ? m : `0${m}`;
    d = d > 9 ? d : `0${d}`;

    return `${date.getFullYear()}-${m}-${d}` as string;
  }

  public formatBirthAt(value: any): string {
    const date = new Date(value);

    let m: number | string = date.getMonth() + 1;
    let d: number | string = date.getDate();
    m = m > 9 ? m : `0${m}`;
    d = d > 9 ? d : `0${d}`;

    return `${d}/${m}/${date.getFullYear()}` as string;
  }
}
