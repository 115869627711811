<div class="empty">
  <div class="empty__container">
    <i [className]="'fas '+ icon"></i>
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
    <ion-button [routerLink]="[url]" [queryParams]="queryParams" *ngIf="url">
      {{ label }}
    </ion-button>
  </div>
</div>
