import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private static TOASTS_KEY = 'errors.';

  constructor(private toast: ToastController, private translate: TranslateService) {}

  async toastError(msg: string, duration = 2000, buttons: ToastButton[] = []): Promise<void> {
    const toast = await this.toast.create({
      message: this.translate.instant(ToastService.TOASTS_KEY + msg),
      duration,
      position: 'top',
      color: 'danger',
      buttons,
    });
    await toast.present();
  }

  async toastSuccess(msg: string, duration?: number): Promise<void> {
    const toast = await this.toast.create({
      message: this.translate.instant(ToastService.TOASTS_KEY + msg),
      duration: duration || 5000,
      position: 'top',
      color: 'success',
    });
    await toast.present();
  }

  async warning(msg: string): Promise<void> {
    const toast = await this.toast.create({
      message: this.translate.instant(ToastService.TOASTS_KEY + msg),
      duration: 5000,
      position: 'top',
      color: 'warning',
    });
    await toast.present();
  }
}
