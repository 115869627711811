import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent {
  @Input() public icon!: string;

  @Input() public title!: string;

  @Input() public url!: string;

  @Input() public queryParams!: { [key: string]: any };

  @Input() public label!: string;

  @Input() public description!: string;
}
