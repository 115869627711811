import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';

export interface ISortable {
  sort: string;
  direction: string;
}

@Component({
  selector: 'app-sortable-item',
  templateUrl: './sortable-item.component.html',
  styleUrls: ['./sortable-item.component.scss'],
})
export class SortableItemComponent implements OnInit, OnChanges {
  @Input() name!: string;

  @Input() sort!: string;

  @Input() current: ISortable = { sort: '', direction: '' };

  @Output() selected: EventEmitter<ISortable> = new EventEmitter<ISortable>();

  public direction = '';

  ngOnInit() {
    this.initState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.current) {
      this.initState();
    }
  }

  private initState(): void {
    this.direction = '';

    if (this.current && this.current.sort === this.sort) {
      this.direction = this.current.direction;
    }
  }

  public onClick(): void {
    const direction = this.direction === 'DESC' ? 'ASC' : 'DESC';

    this.selected.emit({
      sort: this.sort,
      direction,
    });
  }
}
