import { Injectable } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private toast: ToastService, private clipboard: ClipboardService) {}

  public copy(data: string, msg = 'LINK_COPIED'): void {
    const subject = this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
      if (res.isSuccess) {
        this.toast.toastSuccess(msg);
      }
    });

    this.clipboard.copy(data);

    subject.unsubscribe();
  }

  public range(start: number, end: number): number[] {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }
}
