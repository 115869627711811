import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MediaService } from '@shared/requests/media.service';
import { HttpEventType } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Media from '@shared/models/Media';

export interface IFileUpload {
  file: File;
  visibility?: string;
  type?: string;
  isValid?: boolean;
}

export interface IDeleteFile {
  index: number;
  success: boolean;
  id: string;
}

@UntilDestroy()
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output() private fileUploaded = new EventEmitter<Media | boolean>();

  @Output() private deleteUploaded = new EventEmitter<IDeleteFile>();

  @Input() public index = 0;

  @Input() public delete = false;

  @Input() public upload!: IFileUpload;

  @Input() public light = false;

  public progress = 0;

  public success!: boolean;

  private id!: string;

  constructor(private http: MediaService) { }

  public ngOnInit(): void {
    this.http
      .create(this.upload.file, this.upload.visibility, this.upload.type)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (e) => {
          switch (e.type) {
            case HttpEventType.UploadProgress:
              // @ts-ignore
              this.progress = Math.round((e.loaded / e.total) * 100);
              break;
            case HttpEventType.Response:
              const response = e.body;
              if (!response) {
                return;
              }
              let media = new Media();
              media = response as Media;
              this.id = media.id;
              this.fileUploaded.next(media);
              this.success = true;
              break;
          }
        },
        error: () => {
          this.success = false;
          this.upload.isValid = false;
          this.fileUploaded.next(false);
        },
      });
  }

  public formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  public onDelete(): void {
    this.deleteUploaded.next({
      success: this.success,
      index: this.index,
      id: this.id,
    });
  }
}
