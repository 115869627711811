import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(private readonly updates: SwUpdate, private toastService : ToastService) {
    this.updates.versionUpdates.subscribe(async (evt) => {
      switch (evt.type) {
        case 'VERSION_READY':
          this.showAppUpdateAlert();
          break;
      }
    });
  }

  init() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(() => {});
    } else { /* empty */ }
  }

  showAppUpdateAlert() {
    this.toastService.warning('UPDATE_SERVICE_WORKER');

    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      this.doAppUpdate();
    }, 2000);
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => {
      this.toastService.toastSuccess('UPDATE_SERVICE_WORKER_DONE');
      // eslint-disable-next-line angular/timeout-service
      setTimeout(() => {
        document.location.reload();
      }, 2000);
    });
  }
}
