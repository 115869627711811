/**
 * Get File object from a base64.
 *
 * @param base64 Picture Base64
 * @returns Promise of File
 */
export async function getFileFromBase64(base64: string, fileType: string): Promise<any> {
  let file = null;
  await fetch(base64)
    .then((result) => result.blob())
    .then((blob) => {
      file = new File([blob], 'file', { type: fileType });
      return file;
    });
  return file;
}
