export const VISIBILITY_PUBLIC = 'public';

export const VISIBILITY_PRIVATE = 'private';

export const TYPE_IDENTITY = 'identity';

export const TYPE_ASSURANCE = 'assurance';

export const TYPE_PICTURE = 'picture';

export const TYPE_DOCUMENT = 'document';

export const TYPE_QRCODE = 'qrcode';

export const TYPE_BILL = 'bill';

export const SCOPE_USER = 'user';

export const SCOPE_BOND = 'bond';

export default class Media {
  public id!: string;

  public path?: string;

  public type!: string;

  public originalName!: string;

  public deletable?: boolean;
}
