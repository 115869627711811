import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';
import Property from '@shared/models/Property';
import { HttpParams } from '@angular/common/http';
import Count from '@shared/models/Count';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyService extends AbstractRequest {
  public create(property: Property): Observable<Property> {
    return this.http.post<Property>(`${this.endpoint}/property`, property);
  }

  public update(property: Property): Observable<Property> {
    return this.http.put<Property>(`${this.endpoint}/property/${property.id}`, property);
  }

  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/property/${id}`);
  }

  public fetch(id: number): Observable<Property> {
    return this.http.get<Property>(`${this.endpoint}/property/${id}`);
  }

  public fetchByName(name: string): Observable<Property[]> {
    return this.http.get<Property[]>(`${this.endpoint}/property/name?name=${name}`);
  }

  public cancel(id: string): Observable<Property> {
    return this.http.get<Property>(`${this.endpoint}/property/${id}/cancel`);
  }

  public finish(id: string): Observable<Property> {
    return this.http.get<Property>(`${this.endpoint}/property/${id}/finish`);
  }

  public qrcode(id: number): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/property/${id}/qrcode`);
  }

  public fetchAll(
    page?: number,
    items?: number,
    state?: Array<string> | null,
    sortBy?: string | null,
    sortDirection?: string | null,
    search?: string | null,
  ): Observable<Property[]> {
    const names = ['page', 'items', 'state', 'sortBy', 'sortDirection', 'search'];
    const args = this.formatForStateArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Property[]>(`${this.endpoint}/property`, {
        params,
      });
  }

  public count(
    state?: Array<string> | null,
    sortBy?: string,
    sortDirection?: string,
    search?: string,
  ): Observable<Count> {
    const names = ['state', 'sortBy', 'sortDirection', 'search'];
    const args = this.formatForStateArgs(names, arguments);

    const params = new HttpParams({
      fromObject: args,
    });

    return this.http
      .get<Count>(`${this.endpoint}/property/count`, {
        params,
      });
  }
}
