import { Injectable } from '@angular/core';
import { AbstractRequest } from '@shared/requests/AbstractRequest';
import Media, { SCOPE_USER, TYPE_DOCUMENT, VISIBILITY_PUBLIC } from '@shared/models/Media';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends AbstractRequest {
  public create(file: File, type?: string, visibility = VISIBILITY_PUBLIC): Observable<HttpEvent<Media>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('visibility', visibility);
    formData.append('type', type ?? TYPE_DOCUMENT);

    return this.http.post<Media>(`${this.api}/medias`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.api}/medias/${id}`);
  }

  public zipCheck(id: string): string {
    return `${this.api}/zip/check/${id}`;
  }

  public zipTransaction(id: string): string {
    return `${this.api}/zip/transaction/${id}`;
  }

  public zipCashOut(id: string | undefined, type: string): string {
    return `${this.api}/zip/cash-out/${id}/${type}`;
  }
}
