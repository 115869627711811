import Media from './Media';

export default class Property {
  public id!: number;

  public link?: string;

  public type!: string;

  public name!: string;

  public amount!: number;

  public reason!: string;

  public idArea?: string;

  public reference?: string;

  public datesOptional?: boolean;

  public remoteCheckInOut?: boolean;

  public vigilancePoints?: string | string[];

  public welcomeMessage?: string;

  public confirmMessage?: string;

  public createdAt?: Date;

  public state?: string;

  public medias?: Media[];
}
