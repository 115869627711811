export const FILE_TYPE = {
  png: {
    mime: 'image/png',
    format: 'png',
  },
  jpeg: {
    mime: 'image/jpeg',
    format: 'jpeg',
  },
  pdf: {
    mime: 'application/pdf',
    format: 'pdf',
  },
};
