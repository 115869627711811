<div class="pagination">
  <div class="pagination__container">
    <ion-button fill="none" *ngIf="page > 1" (click)="onClick(page - 1)">
      <i class="fas fa-chevron-left"></i>
    </ion-button>
    <ion-button fill="none" *ngIf="pages[0] > 1" (click)="onClick(1)">
       1
    </ion-button>
    <ion-button fill="none" *ngIf="pages[0] > 2">
      ...
    </ion-button>
    <ion-button
      fill="none"
      *ngFor="let p of pages"
      [ngClass]="{ 'active': p === this.page }"
      (click)="onClick(p)">
      {{ p }}
    </ion-button>
    <ion-button fill="none" *ngIf="pages[pages.length-1] < max - 1">
      ...
    </ion-button>
    <ion-button [ngClass]="{ 'active': max === this.page }" fill="none" (click)="onClick(max)">
      {{ max }}
    </ion-button>
    <ion-button  *ngIf="page < max" fill="none" (click)="onClick(page + 1)">
      <i class="fas fa-chevron-right"></i>
    </ion-button>
  </div>
</div>
