import { DocumentsStatusEnum } from '@shared/enums/documents-status.enum';
import Media from '@shared/models/Media';

export default class Document {
  public id!: number;

  public type!: string;

  public status!: string;

  public medias: Media[] = [];

  public isValid = true;

  constructor(type: string, status: string = DocumentsStatusEnum.PROVIDE) {
    this.status = status;
    this.type = type;
  }
}
