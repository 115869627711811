import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { UtilService } from '@shared/services/util.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() page!: number;

  @Input() count!: number;

  @Input() items!: number;

  @Output() selected: EventEmitter<number> = new EventEmitter<number>();

  public pages: number[] = [];

  public max!: number;

  constructor(private util: UtilService) {}

  ngOnInit(): void {
    this.initState();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.initState();
  }

  private initState(): void {
    this.max = Math.ceil(this.count / this.items);
    const start = this.page - 2 > 0 ? this.page - 2 : 1;
    const end = this.page + 3 < this.max ? this.page + 3 : this.max;

    this.pages = this.util.range(start, end);
  }

  public onClick(page: number): void {
    this.selected.emit(page);
  }
}
