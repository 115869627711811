import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DocumentsStatusEnum } from '@shared/enums/documents-status.enum';
import { PropertyType } from '@shared/enums/property-type.enum';
import { RuleFormValidator } from '@shared/enums/rule-form-validator.enum';
import IFrontError from '@shared/interfaces/IFrontError';
import { FormValidator } from '@shared/models/FormValidator';
import Property from '@shared/models/Property';
import { PropertyService } from '@shared/requests/property.service';
import { ToastService } from '@shared/services/toast.service';
import Document from '@shared/models/Document';
import Bond from '@shared/models/Bond';
import { BondService } from '@shared/requests/bond.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import Count from '@shared/models/Count';
import { ISortable } from '@shared/components/sortable-item/sortable-item.component';
import { environment } from '@env/environment';
import { UtilService } from '@shared/services/util.service';
import { isArray, tap } from 'lodash';
import { DateTime } from 'luxon';
import { log } from 'console';

@UntilDestroy()
@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.page.html',
  styleUrls: ['./property-details.page.scss'],
})
export class PropertyDetailsPage implements OnInit {
  public frontErrors: IFrontError = {
    lastName: '',
    firstName: '',
    birthCity: '',
    city: '',
    country: '',
    postalCode: '',
    street: '',
    phone: '',
    birthAt: '',
    birthCountry: '',
    nationality: '',
    streetCompany: '',
    postalCodeCompany: '',
    cityCompany: '',
    countryCompany: '',
    type: '',
    name: '',
    amount: '',
    reason: '',
    idArea: '',
    startAt: '',
    endAt: '',
    email: '',
  };

  public link = environment.link;

  public loading = false;

  public checked = false;

  public pageLoaded = false;

  public property: Property = new Property();

  public error = false;

  public ruleFormValidator = RuleFormValidator;

  public PropertyType = PropertyType;

  public validator!: FormValidator;

  public bondList: Observable<Bond[]|null> = new Observable<Bond[]|null>();

  public range: Date[] = [];

  public count = new Count();

  public state = 'all';

  public search = '';

  public page = 1;

  public onglet = 1;

  public Area = {
    ComplementaryInformations: true,
    Message: false,
    Documents: false,
  };

  public sort: ISortable = {
    sort: 'b.createdAt',
    direction: 'DESC',
  };

  public documentsErrors: string[] = [];

  public docs: Document[] = [];

  /**
   * List of status.
   */
  public documentStatusEnum = DocumentsStatusEnum;

  constructor(
    private title: Title,
    private router: NavController,
    private toast: ToastService,
    private route: ActivatedRoute,
    private http: PropertyService,
    private httpBond: BondService,
    private translate: TranslateService,
    private util: UtilService
  ) {
    this.validator = new FormValidator(this.translate);
  }

  async ngOnInit() {
    this.title.setTitle('Tyllt - Detail du lien de demande');
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const id = params.get('id');
      this.resetPage();
      if (id) {
        this.request(parseInt(id, undefined));
      } else {
        this.router.navigateRoot('/owner/properties');
      }
    });
    this.docs.push(new Document(''));
  }

  public resetPage() {
    this.docs = [];
    if (this.property) {
      this.property = new Property();
    }
  }

  public setArea(area: string): void {
    if (area === 'ComplementaryInformations') {
      this.Area[area] = !this.Area[area];
    } else if (area === 'Message') {
      this.Area[area] = !this.Area[area];
    } else if (area === 'Documents') {
      this.Area[area] = !this.Area[area];
    }
  }

  public onFileUploaded(): void {
    this.property.medias = this.docs[0].medias;
  }

  public setOnglet(onglet: number) {
    this.onglet = onglet;
    this.pageLoaded = true;
  }

  public request(id: number): void {
    forkJoin([this.http.fetch(id)])
      .pipe(
        switchMap(([property]) => {
          this.property = property;

          this.fetchBonds();

          if (this.property.medias?.length) {
            this.property.medias.forEach((media) => {
              media.deletable = true;
            });
            this.docs[0].medias = this.property.medias;
          }

          if (isArray(this.property.vigilancePoints)) {
            this.property.vigilancePoints = this.property?.vigilancePoints?.toString();
          }

          return of(null);
        })
      )
      .subscribe();
  }

  public updatePage($event: number): void {
    this.page = $event;
    this.fetchBonds();
  }

  public onCopy(copy: string): void {
    this.util.copy(copy);
  }

  private fetchBonds(): boolean {
    this.loading = true;
    const state = this.state === 'all' ? null : this.state;
    const date = ['', ''];

    this.httpBond.fetchBondListByProperty(
      this.page,
      6,
      state,
      'classic',
      this.sort.sort,
      this.sort.direction,
      this.search,
      date[0],
      date[1],
      this.property.id
    ).subscribe({
      next: (bonds) => {
        this.bondList = of(bonds);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        // Gérer l'erreur ici
      },
    });

    this.httpBond.countByProperty(
      state,
      'classic',
      this.sort.sort,
      this.sort.direction,
      this.search,
      date[0],
      date[1],
      this.property.id
    ).subscribe({
      next: (count) => {
        this.count = count;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        // Gérer l'erreur ici
      },
    });

    return true;
  }

  public enabledSubmit(): boolean | void {
    const amount: string = typeof this.property.amount === 'number' ? this.property.amount.toString() : '';
    const errors: Array<boolean> = [];

    this.frontErrors = this.validator.fieldValidator([
      [[this.ruleFormValidator.required], 'type', this.property.type],
      [[this.ruleFormValidator.required], 'name', this.property.name],
      [[this.ruleFormValidator.required], 'amount', amount],
      [[this.ruleFormValidator.required], 'reason', this.property.reason],
    ]);

    if (this.property.type === this.PropertyType.MULTIPLE) {
      this.frontErrors = this.validator.fieldValidator([
        [[this.ruleFormValidator.required], 'idArea', this.property.idArea as string],
      ]);
    } else if (this.property.type === this.PropertyType.UNIQUE && this.frontErrors.idArea !== '') {
      this.frontErrors.idArea = '';
    }

    for (const error in this.frontErrors) {
      if (this.frontErrors[error]) {
        errors.push(true);
      }
    }

    if (!errors.length) {
      return true;
    }
    return false;
  }

  public isValidate(label: string): void {
    const amount: string = typeof this.property.amount === 'number' ? this.property.amount.toString() : '';
    if (label === 'type') {
      this.frontErrors = this.validator.fieldValidator([
        [[this.ruleFormValidator.required], 'type', this.property.type],
      ]);
      if (this.property.type === this.PropertyType.UNIQUE && this.frontErrors.idArea !== '') {
        this.frontErrors.idArea = '';
      }
    }
    if (label === 'name') {
      this.frontErrors = this.validator.fieldValidator([
        [[this.ruleFormValidator.required], 'name', this.property.name],
      ]);
    }
    if (label === 'amount') {
      this.frontErrors = this.validator.fieldValidator([[[this.ruleFormValidator.required], 'amount', amount]]);
    }
    if (label === 'reason') {
      this.frontErrors = this.validator.fieldValidator([
        [[this.ruleFormValidator.required], 'reason', this.property.reason],
      ]);
    }
    if (label === 'idArea' && this.property.type === this.PropertyType.MULTIPLE) {
      this.frontErrors = this.validator.fieldValidator([
        [[this.ruleFormValidator.required], 'idArea', this.property.idArea as string],
      ]);
    }
  }

  public onAmountChange($event: Event): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const amount = $event.target?.value;

    if (amount > 2500) {
      void this.toast.warning('BOND_SUPERIOR_2500');
    }
  }

  public onSubmit(): void {
    if (this.enabledSubmit()) {
      this.loading = true;
      this.http
        .update(this.property)
        .pipe(
          switchMap(() => {
            this.loading = false;
            this.error = false;
            void this.toast.toastSuccess('ASSET_UPDATED');
            return of(null);
          }),
          catchError(() => {
            void this.toast.toastError('INVALID_FORM');
            this.loading = false;
            this.error = true;
            return of(null);
          }),
          finalize(() => {
            // Actions finales (si nécessaire)
          })
        )
        .subscribe();
    } else {
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView(false);
        top = null;
      }

      this.error = true;
    }
  }
}
