import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-button',
  templateUrl: './load-button.component.html',
  styleUrls: ['./load-button.component.scss'],
})
export class LoadButtonComponent {
  @Input() public label!: string;

  @Input() public color = 'primary';

  @Input() public loading!: boolean;

  @Input() public disabled!: boolean | null;

  @Input() public type = 'submit';

  @Input() public class = '';
}
