import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/guard/auth.guard';
import { OwnerGuard } from '@auth/guard/owner.guard';
import { TenantGuard } from '@auth/guard/tenant.guard';
import { AdminGuard } from '@auth/guard/admin.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'owner',
    loadChildren: () => import('./owner/owner.module').then((m) => m.OwnerModule),
    canActivate: [AuthGuard, OwnerGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard, TenantGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '',
    redirectTo: '/auth/dispatch',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
